window.onscroll = function () {
	scrollFunctionBTT(); // back to top button
};

/* Back To Top Button */
// Get the button
upButton = document.getElementById("upButton");

// When the user scrolls down 20px from the top of the document, show the button
function scrollFunctionBTT() {
	if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
		upButton.style.display = "block";
	} else {
		upButton.style.display = "none";
	}
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
	document.body.scrollTop = 0; // for Safari
	document.documentElement.scrollTop = 0; // for Chrome, Firefox, IE and Opera
}

document.getElementById('upButton').addEventListener('click', topFunction)